import React from 'react';
import Alert from './Alert';

const ErrorMessageComponent = ({error}) => {
  if(!error){
    return null
  }
  return (
    <Alert className="error" colors="error">
      {error}
    </Alert>
  )
};

export default ErrorMessageComponent;
