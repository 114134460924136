import React from 'react';
import Alert from './Alert';

const SuccessMessageComponent = ({ message }) => {
  if (!message) {
    return null;
  }
  return (
    <Alert className="success" colors="primary">
      {message}
    </Alert>
  );
};

export default SuccessMessageComponent;
